<template>
  <user-pages-wrapper title="History">
    <div>
      <div v-if="cars_viewed.length">
        <section ref="section2">
          <div class="px-3 my-5">
            <b-row
              class="match-height"
            >
              <b-col
                v-for="(car, i) in cars_viewed"
                :key="i"
                cols="12" 
                md="3" 
              >
                <user-car-display-card 
                  :car="car" 
                  :is-favorite="isFavoriteCar(car._id)"
                  :is-history="true"
                  @refetchData="fetchFavoriteCars"
                  @refetchHistory="fetchCarsViewedHistory"
                />
              </b-col>
            </b-row>
          </div>
        </section>
      </div>
      <div v-else>
        <h3 class="text-dark">No cars in your view history</h3>
      </div>
    </div>
  </user-pages-wrapper>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BLink,
  BOverlay,
  BCardText,
  BJumbotron,
} from 'bootstrap-vue';

import { get } from "lodash";
import UserPagesWrapper from "@/@core/layouts/components/UserPagesWrapper.vue";
import UserCarDisplayCard from '@/@core/components/shared/UserCarDisplayCard.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    UserPagesWrapper,
    BRow,
    BCol,
    BImg,
    BLink,
    BOverlay,
    BCardText,
    BJumbotron,
    UserCarDisplayCard
  },
  data() {
    return {
      loading: false,
      favorite_cars: [],
      cars_viewed: [],
    }
  },
  created(){
    this.fetchCarsViewedHistory()
    this.fetchFavoriteCars()
  },
  methods: {
    async fetchCarsViewedHistory() {
      try {
        this.loading = true;

        const query = {
          search: this.currentUser._id,
        }

        const request = await this.useJwt().ristic.carsViewedHistory.list(query);
        const { data } = request.data;

        this.cars_viewed = data.map(this.makeCarDisplayData);
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchFavoriteCars() {
      try {
        this.loading = true;

        const query = {
          search: this.currentUser._id,
        }

        const request = await this.useJwt().ristic.favoriteCars.list(query);
        const { data } = request.data;

        this.favorite_cars = data
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    isFavoriteCar(car_id) {
      return this.favorite_cars.some(el => el.car_id === car_id);
    },
    makeCarDisplayData(car) {
      const car_pricing = this.getValueFromSource(car, "car_pricing")
      const default_location = car_pricing.find(el => el.default_location)
      return {
        title: this.getValueFromSource(car, "car.car_name"),
        description: this.getValueFromSource(car, "car.car_description"),
        image: this.getValueFromSource(car, "car.car_images[0].image.path"),
        brand_title: this.getValueFromSource(car, "car.car_brand.title"),
        make_title: this.getValueFromSource(car, "car.car_make.title"),
        car_model_year: this.getValueFromSource(car, "car.car_model_year"),
        rate: default_location.amount,
        ...car.car,
      };
    },
  }
}
</script>

