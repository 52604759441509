<template>
  <div class="mx-4 pb-4 my-5">
    <b-overlay :show="loading">
      <h1 class="text-28" style="padding-bottom: 35px; padding-top: 60px">
        {{ title }}
      </h1>
      <div class="title-divider"></div>
      <div class="py-4" style="min-height: 70vh">
        <slot></slot>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay } from 'bootstrap-vue';

export default {
  name: "UserPagesWrapper",
  components: {
    BOverlay,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style>
.user-pages-wrapper {
  background-color: #fff;
}
</style>
